<template>
  <default-layout>
    <div class="offering--header">
      <div class="container">
        <div class="offering--title">
          {{ $t('offering.title') }}
        </div>
        <div class="offering--desc">
          {{ $t('offering.desc') }}
        </div>
      </div>
    </div>
    <div class="offering--content">
      <div class="container">
        <div class="offering--table">
          <div class="title--text">{{ $t('offering.choosePackage') }}</div>
          <div class="offering--detail">
            <card-offerings
              class="single--offering"
              v-for="product in accountProducts"
              :offering="product"
              :key="product.uuid"
            ></card-offerings>
          </div>
          <!--          <div class="offering&#45;&#45;detail" style="margin-top: 0">-->
          <!--            <card-offerings-->
          <!--              class="single&#45;&#45;offering"-->
          <!--              v-for="product in secondRowProducts"-->
          <!--              :offering="product"-->
          <!--              :key="product.uuid"-->
          <!--            ></card-offerings>-->
          <!--          </div>-->
          <!--          <div class="price&#45;&#45;disclaimer mt-2">*{{ $t('offering.priceDesc1') }}</div>-->
          <!--          <div class="price&#45;&#45;disclaimer">**{{ $t('offering.priceDesc2') }}</div>-->
          <div class="price--disclaimer mt-2">
            *<a :href="`${userWebsiteUrl}/syarat-ketentuan`" target="_blank"
              >Syarat dan Ketentuan</a
            >
            Lainnya Berlaku
          </div>
        </div>
      </div>
    </div>
    <div class="offering--footer">
      <div class="container">
        <div class="offering--faq">
          <div class="faq--title">{{ $t('offering.faq') }}</div>
          <ol>
            <li>
              <div class="faq--question">{{ $t('offering.q1') }}</div>
              <div class="faq--answer">{{ $t('offering.a1') }}</div>
            </li>
            <li>
              <div class="faq--question">{{ $t('offering.q2') }}</div>
              <div class="faq--answer">{{ $t('offering.a2') }}</div>
            </li>
          </ol>
        </div>
        <div class="offering--action">
          <div>
            <button class="btn btn-primary" @click="action">
              <span>{{ actionText }}</span>
            </button>
            <div class="disclaimer">
              *<a :href="`${userWebsiteUrl}/syarat-ketentuan`" target="_blank"
                >Syarat dan Ketentuan</a
              >
              Lainnya Berlaku
            </div>
            <div class="back--to-main-website">
              <a :href="userWebsiteUrl">{{ $t('offering.backToMainWebsite') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
import CardOfferings from '@/components/agent-offerings/card-offerings';
import { mapGetters, mapState } from 'vuex';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    CardOfferings,
  },
  computed: {
    ...mapState({
      loggedIn: (state) => state.global.loggedIn,
      user: (state) => state.global.user,
      accountProducts: (state) => state.product.accountProducts,
    }),
    ...mapGetters({
      firstRowProducts: 'product/firstRowProducts',
      secondRowProducts: 'product/secondRowProducts',
    }),
    userWebsiteUrl() {
      return process.env.VUE_APP_USER_URL;
    },
    actionText() {
      let text = '';
      if (!this.loggedIn) {
        text = 'Daftar Sebagai Agen';
      } else if (this.user && !this.user.membership) {
        text = 'Beli Paket Membership';
      } else {
        text = 'Beli Paket Koin / Upgrade';
      }
      return text + '*';
    },
  },
  methods: {
    action() {
      if (!this.loggedIn) {
        this.$router.push({
          path: '/register',
        });
      } else if (this.user && !this.user.membership) {
        this.$router.push({
          path: '/product',
        });
      } else {
        this.$router.push({
          path: '/coin',
        });
      }
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch('product/getMainPackages');
    } catch (e) {
      console.log('ERROR LOAD MAIN PACKAGES: ', e);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/utils/variables';
.offering--header {
  background-color: $color-beliruma-1;
  .container {
    max-width: 700px;
    padding-top: 80px;
    padding-bottom: 80px;

    @media #{$phones} {
      padding: 80px 40px;
    }
  }
}
.offering--title {
  font-family: Poppins-Bold;
  font-size: $font-xdl;
  color: $color-white;
  text-align: center;
  margin-bottom: 22px;
  @media #{$phones} {
    font-size: $font-xxl;
    margin-bottom: 12px;
  }
}
.offering--desc {
  font-size: $font-ml;
  color: $color-white;
  text-align: center;
  @media #{$phones} {
    font-size: $font-md;
  }
}
.offering--content {
  margin: auto;
  padding-top: 80px;
  padding-bottom: 80px;

  @media #{$phones} {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.offering--table {
  //width: 1000px;
  max-width: 100%;

  @media #{$phones} {
    width: 100%;
  }

  .title--text {
    font-family: Poppins-SemiBold;
    font-size: $font-xxl;
    color: $color-text-black;
    text-align: center;
    @media #{$phones} {
      font-size: $font-ml;
    }
  }
  .offering--detail {
    display: flex;
    justify-content: center;
    margin: 60px -30px;

    @media #{$tablets} {
      flex-wrap: wrap;
      margin: 60px -10px 0;
    }

    @media #{$phones} {
      flex-wrap: wrap;
      margin: 40px 0 0;
    }

    .single--offering {
      width: calc(33.33% - 36px);
      margin: 0 12px;

      @media #{$tablets} {
        width: calc(50% - 20px);
        margin: 0 10px 60px 10px;
      }

      @media #{$phones} {
        width: calc(100% - 60px);
        margin: 0 0 60px;
      }
    }
  }
  .price--disclaimer {
    font-size: $font-sm;
    color: $color-text-gray-3;
    margin-left: 16px;
    a {
      color: $color-beliruma-1;
      text-decoration: none;
    }
  }
}
.offering--footer {
  background-color: $color-beliruma-1-light;
  .container {
    max-width: 800px;
    padding-top: 80px;
    padding-bottom: 20px;
    @media #{$phones, $tablets} {
      padding: 40px 30px 0;
    }
  }
}
.offering--faq {
  .faq--title {
    font-family: Poppins-SemiBold;
    font-size: $font-xxl;
    color: $color-text-black;
    margin-bottom: 20px;
    @media #{$phones} {
      font-size: $font-md;
    }
  }
  .faq--question {
    font-family: Poppins-SemiBold;
    font-size: $font-lg;
    color: $color-text-black;
    @media #{$phones} {
      font-size: $font-sm;
    }
  }
  .faq--answer {
    color: $color-text-black;
    font-size: $font-lg;
    margin-bottom: 20px;
    @media #{$phones} {
      font-size: $font-sm;
    }
  }
}
.offering--action {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 48px;
  @media #{$phones} {
    margin-top: 12px;
    margin-bottom: 24px;
  }
  .btn {
    height: 60px;
    border-radius: 30px;
    margin: auto;
    font-size: $font-xxl;
    padding-left: 36px;
    padding-right: 36px;
    @media #{$phones} {
      height: 40px;
      border-radius: 20px;
      font-size: $font-sm;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .disclaimer {
    color: $color-text-black;
    text-align: center;
    margin-top: 4px;
    a {
      color: $color-beliruma-1;
      text-decoration: none;
    }
    @media #{$phones} {
      font-size: $font-xs;
    }
  }
  .back--to-main-website {
    width: 100%;
    margin-top: 40px;
    text-align: center;
    a {
      color: $color-beliruma-1;
      text-decoration: none;
    }
  }
}
</style>
